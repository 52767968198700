import Vue from 'vue'
import VueRouter from 'vue-router'
import permissions from '@/libs/permissions'
import {Tiers} from '@/libs/enums/Tiers'

Vue.use(VueRouter)

async function useStore() {
  return (await import('@/store')).default
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from) {
    if (to.path !== from.path) {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Dashboard/Dashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/installation-wizard',
      name: 'installation-wizard',
      component: () => import('@/views/InstallationWizard.vue'),
      meta: {
        layout: 'full-with-nav',
        requiresAuth: true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/User/Users.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.UserConfigurationRead]
      }
    },
    {
      path: '/tips',
      name: 'tips',
      component: () => import('@/views/Tips/Tips.vue'),
      meta: {
        requiresAuth: true,
        role:[Vue.prototype.$permissions.UserConfigurationRead],
        tier: [Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/issued_bills',
      name: 'issued_bills',
      component: () => import('@/views/Bills/Bills.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ReportingRead]
      }
    },
    {
      path: '/issued_bills/bill/:bill_id',
      name: 'preview_bill',
      component: () => import('@/views/Bills/PreviewBill.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ReportingRead]
      }
    },
    {
      path: '/vat',
      name: 'vat',
      component: () => import('@/views/VAT/VAT.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.GeneralRead]
      }
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/Product/Products.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.ProductsRead]
      }
    },
    {
      path: '/products-in-business-units',
      name: 'products_in_business_units',
      component: () => import('@/views/Product/ProductsInBusinessUnits.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.ProductsRead]
      }
    },
    {
      path: '/product-categories',
      name: 'product_categories',
      component: () => import('@/views/ProductCategory/ProductCategories.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.ProductsRead]
      }
    },
    {
      path: '/product-subcategories',
      name: 'product_subcategories',
      component: () => import('@/views/ProductSubcategory/ProductSubcategories.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.ProductsRead]
      }
    },
    {
      path: '/product-order-options',
      name: 'product_order_options',
      component: () => import('@/views/ProductOrderOption/ProductOrderOptions.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.ProductsRead]
      }
    },
    {
      path: '/product-display-groups',
      name: 'product_display_groups',
      component: () => import('@/views/ProductDisplayGroup/ProductDisplayGroups.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.ProductsRead]
      }
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('@/views/Employee/Employees.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.GeneralRead]
      }
    },
    {
      path: '/employee/:id',
      name: 'employee',
      component: () => import('@/views/Employee/Employee.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.GeneralRead]
      }
    },
    {
      path: '/employee-permission-groups',
      name: 'employee_permission_groups',
      component: () => import('@/views/EmployeePermissionGroup/EmployeePermissionGroups.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.GeneralRead]
      }
    },
    {
      path: '/payment-types',
      name: 'payment_types',
      component: () => import('@/views/PaymentType/PaymentTypes.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.GeneralRead]
      }
    },
    {
      path: '/predefined-discounts',
      name: 'predefined_discounts',
      component: () => import('@/views/PredefinedDiscount/PredefinedDiscounts.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.GeneralRead]
      }
    },
    {
      path: '/buyers',
      name: 'buyers',
      component: () => import('@/views/Buyer/Buyers.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.GeneralRead]
      }
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      component: () => import('@/views/Supplier/Suppliers.vue'),
      meta: {
        requiresAuth: true,
        role:[permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/warehouses',
      name: 'warehouses',
      component: () => import('@/views/Warehouse/Warehouses.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/warehouse_stock/:warehouse_id',
      name: 'warehouse_stock',
      component: () => import('@/views/Warehouse/WarehouseStock.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/work_premises',
      name: 'work_premises',
      component: () => import('@/views/WorkPremises/JoinedWorkPremises.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/business_units',
      name: 'business_units',
      component: () => import('@/views/BusinessUnits/BusinessUnits.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead]
      }
    },
    /*{
      path: '/work_premises/:id',
      name: 'view_room',
      component: () => import('@/views/WorkPremises/ViewRoom.vue'),
      meta: {
        requiresAuth: true,
        //role: [permissions.ConfigurationWrite],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },*/

    {
      path: '/modificators',
      name: 'modificators',
      component: () => import('@/views/Modificator/Modificators.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ProductsRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/printer-configs',
      name: 'printer_configs',
      component: () => import('@/views/PrinterConfig/PrinterConfigs.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead]
      }
    },
    {
      path: '/logo',
      name: 'logo',
      component: () => import('@/views/Logo/Logos.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead]
      }
    },
    {
      path: '/ingredients',
      name: 'ingredients',
      component: () => import('@/views/Ingredient/Ingredients.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ProductsRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/ingredients-category',
      name: 'ingredientsCategory',
      component: () => import('@/views/Ingredient/IngredientCategory'),
      meta: {
        requiresAuth: true,
        role: [permissions.ProductsRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/ingredients-subcategory',
      name: 'ingredient_subcategories',
      component: () => import('@/views/Ingredient/subcategory/IngredientSubcategory.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ProductsRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/delivery-notes',
      name: 'delivery_notes',
      component: () => import('@/views/DeliveryNote/DeliveryNotes.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/delivery-notes/:id',
      name: 'edit_delivery_note',
      component: () => import('@/views/DeliveryNote/EditDeliveryNote'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/write-offs/edit/:id',
      name: 'edit_write_off',
      component: () => import('@/views/WriteOff/EditWriteOff'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/write-offs/view/:id',
      name: 'view_write_off',
      component: () => import('@/views/WriteOff/EditWriteOff'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/move-supplies/edit/:id',
      name: 'edit_move_supply',
      component: () => import('@/views/MoveSupplies/EditMoveSupply'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/stock_takings/:id',
      name: 'edit_stock_taking',
      component: () => import('@/views/StockTaking/EditStockTaking'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/price-list',
      name: 'price_list',
      component: () => import('@/views/PriceList/PriceList.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ProductsRead]
      }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/Calendar/Calendar.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ProductsRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/cash-register',
      name: 'cash_register',
      component: () => import('@/views/CashRegister/CashRegister.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead]
      }
    },
    {
      path: '/move-supply',
      name: 'move_supply',
      component: () => import('@/views/MoveSupplies/AddWarehousesMoveSupply.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement]
      }
    },
    {
      path: '/stock-taking',
      name: 'stock_taking',
      component: () => import('@/views/StockTaking/StockTaking.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/write-off',
      name: 'write_off',
      component: () => import('@/views/WriteOff/WriteOff.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/furs-certificates',
      name: 'furs_certificates',
      component: () => import('@/views/FURSCertificate/FURSCertificates.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead]
      }
    },
    {
      path: '/unissued-invoices',
      name: 'unissued_invoices',
      component: () => import('@/views/Invoices/Unissued/Invoices.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InvoicesRead]
      }
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/Invoices/Issued/Invoices.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InvoicesRead]
      }
    },
    {
      path: '/move-supplies',
      name: 'move_supplies',
      component: () => import('@/views/MoveSupplies/MoveSupplies.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InventoryManagement],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/add-invoice',
      name: 'add_invoice',
      component: () => import('@/views/Invoices/Issued/AddInvoice.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InvoicesWrite]
      }
    },
    {
      path: '/edit-invoice/:invoice_id',
      name: 'edit_invoice',
      component: () => import('@/views/Invoices/Issued/EditInvoice.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.InvoicesWrite]
      }
    },
    {
      path: '/configuration',
      name: 'configuration',
      component: () => import('@/views/InvoiceConfiguration/PaymentDeadline.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ConfigurationRead, permissions.ConfigurationWrite]
      }
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/Reports/ReportHomePage.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.ReportingRead]
      }
    },
    {
      path: '/deleted_products',
      name: 'deleted_products',
      component: () => import('@/views/ArchivedOpenTabs/Products.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.DeletedRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/deleted_open_tabs',
      name: 'deleted_open_tabs',
      component: () => import('@/views/ArchivedOpenTabs/OpenTabs.vue'),
      meta: {
        requiresAuth: true,
        role: [permissions.DeletedRead],
        tier:[Tiers.Standard, Tiers.Premium]
      }
    },
    {
      path: '/system_messages',
      name: 'system_messages',
      component: () => import('@/views/SystemMessages/SystemMessages.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/system_message/:message_id',
      name: 'system_message',
      component: () => import('@/views/SystemMessages/Message.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/forgotten-password',
      name: 'forgotten-password',
      component: () => import('@/views/ForgottenPassword.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/password-reset/:link',
      name: 'password-reset',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/register/:registration_link',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false
      }
    },
    {
      path: '/confirm/:confirm_link',
      name: 'confirm',
      component: () => import('@/views/Confirm.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false
      }
    },
    {
      path: '/no-clients',
      name: 'no-clients',
      component: () => import('@/views/NoClients.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true
      }
    },
    {
      path: '/client/:client_id',
      name: 'change-client',
      component: () => import('@/views/ChangeClient.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})
router.beforeEach(async(to, from, next) => {
  const store = await useStore()

  store.state.appConfig.layout.type = 'vertical'

  const routesToRedirectIfLoggedIn = [
    'forgotten-password',
    'login',
    'password-reset'
    // 'register'
  ]

  if (store.getters['user/loggedIn'] && localStorage.getItem('progress') === '0' && (to.meta && to.meta.requiresAuth)) {
    store.state.appConfig.layout.type = 'full-with-nav'
    if (to.path !== '/installation-wizard') {
      return next('/installation-wizard')
    }
  }

  if (!store.getters['user/loggedIn'] && (to.meta && to.meta.requiresAuth === true)) {
    return next('/login')
  } else if (store.getters['user/loggedIn'] && routesToRedirectIfLoggedIn.includes(to.name)) {
    return router.push({name: 'home'})
  }

  // če stran, na katero želimo iti vsebuje meta podatek requiresAuth
  // in iz authService zvemo, da user ni prijavljen, ga pošljemo na login
  if (to.meta.requiresAuth && !store.getters['user/loggedIn']) {
    return next('/login')
  }

  // Preverimo ce imamo to vkljuceno v svoji verziji
  if (to.meta && to.meta.tier && to.meta.tier.length > 0) {
    if (!Vue.prototype.$hasTierPermission(...to.meta.tier)) {
      return next('/error-404')
    }
  }

  // Preverimo ce imamo pravice
  if (to.meta && to.meta.role && to.meta.role.length > 0) {
    let allow = false

    for (const permission of to.meta.role) {
      if (Vue.prototype.$hasPermission(permission)) {
        allow = true
      }
    }

    if (!allow) {
      return next('/error-404')
    }
  }

  next()
})

router.afterEach(() => {

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router